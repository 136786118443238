import React from "react";
import "./Technology_Card.css";

function Technology_Card({headingText, descriptionText, imgSrc }) {
  return (
    <>
      <div className="Technology_Card">
        <div className="Card_img_holder">
          <img
            className="Technology_Card_img"
            src={process.env.PUBLIC_URL + imgSrc}
          />
        </div>
        <div className="text_holder">
          <h4>{headingText}</h4>
        </div>
      </div>
    </>
  );
}

export default Technology_Card;
