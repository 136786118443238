import React from 'react'
import './CRMTechnology.css'
import Service_development_Card from '../../Common_Component/Service_development_Card/Service_development_Card'
import Technology_Card from '../Technology_Card/Technology_Card'
import TechnologyTitle from '../TechnologyTitle/TechnologyTitle'

const CRMTechnology = () => {
    return (
        <section className="CRMTechnology FrontendTechnology Service_development_sec">
            <div className="container ">
                <div className="bg-servicedev">

                  <TechnologyTitle title="CRM Technologies" />

                    <div className="Service_development_Card_Holder">
                        <div className="row">
                            <div className="col-xl-10 col-md-11 col-11 mx-auto ">
                                <div className="row">
                                    <div className="col-lg-4 col-md-4  col-sm-4 col-6 after_border_holder ">
                                        <Technology_Card imgSrc={"/assets/images/technologies/suitcrm-logo.webp"} headingText={"SuitCRM"} />
                                    </div>

                                    <div className="col-lg-4 col-md-4  col-sm-4 col-6  after_border_holder">
                                        <Technology_Card  imgSrc={"/assets/images/technologies/salesforce-logo-1.webp"} headingText={"Salesforce"} />
                                    </div>

                                    <div className="col-lg-4 col-md-4  col-sm-4 col-6 ">
                                        <Technology_Card  imgSrc={"/assets/images/technologies/sugarcrm-logo.webp"} headingText={"SugarCRM"} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CRMTechnology
