import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import './Testimonials.css'

import 'swiper/css';
import 'swiper/css/pagination';

import { Pagination } from 'swiper/modules';

const Testimonials = () => {
    return (
        <>
            <section className='Testimonials_sec'>
                <div className='container-lg'>
                    <div className='text-center'>
                        <p className='sec-title'>What Our Clients Say About us</p>
                        <h5 className='secsub-title'>Real Stories. Proven Success</h5>
                    </div>

                    <div className='slider-sec'>
                        <Swiper
                            breakpoints={{
                                '0': {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                },
                                '575': {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                                '768': {
                                    slidesPerView: 3,
                                    spaceBetween: 20,
                                },
                                '1400': {
                                    slidesPerView: 4,
                                    spaceBetween: 20,
                                },
                            }}

                            pagination={{
                                dynamicBullets: true,
                                clickable: true,
                            }}
                            modules={[Pagination]}
                            className="mySwiper"
                        >
                            <SwiperSlide>
                                <div className='slider-div'>
                                    <div className='top-div'>
                                        <div className='icodiv'>
                                            <img className='icon' src={process.env.PUBLIC_URL + '/assets/images/icons/ulta.png'} />
                                            <img className='icon' src={process.env.PUBLIC_URL + '/assets/images/icons/ulta.png'} />
                                        </div>
                                        <p className='text'>"Profcyma transformed our business processes with their innovative solutions.
                                            Their team was professional, responsive,And  exceeded our expectations Highly
                                            recommended!"</p>
                                    </div>
                                    <div className='prof-div'>
                                        <div className='imgdiv'>
                                            <img className='perimg' src={process.env.PUBLIC_URL + '/assets/images/home/testimonials/person2.jpg'} />
                                        </div>
                                        <div className='namediv my-auto'>
                                            <p className='name'>Aditya Jagtap</p>
                                            <p className='position'>Software Developer</p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className='slider-div'>
                                    <div className='top-div'>
                                        <div className='icodiv'>
                                            <img className='icon' src={process.env.PUBLIC_URL + '/assets/images/icons/ulta.png'} />
                                            <img className='icon' src={process.env.PUBLIC_URL + '/assets/images/icons/ulta.png'} />
                                        </div>
                                        <p className='text'>"Working with Profcyma has been a game changer for our business. Their team helped us streamline our operations with a custom software solution that exceeded our expectations. The support and attention to detail were exceptional."</p>
                                    </div>
                                    <div className='prof-div'>
                                        <div className='imgdiv'>
                                            <img className='perimg' src={process.env.PUBLIC_URL + '/assets/images/home/testimonials/person1.png'} />
                                        </div>
                                        <div className='namediv my-auto'>
                                            <p className='name'>Paresh Shinde</p>
                                            <p className='position'>Civil Engineer</p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className='slider-div'>
                                    <div className='top-div'>
                                        <div className='icodiv'>
                                            <img className='icon' src={process.env.PUBLIC_URL + '/assets/images/icons/ulta.png'} />
                                            <img className='icon' src={process.env.PUBLIC_URL + '/assets/images/icons/ulta.png'} />
                                        </div>
                                        <p className='text'>"We partnered with Profcyma for cloud migration, and the transition was seamless. Their technical expertise and proactive communication made a complex project stress-free. Highly recommended!"</p>
                                    </div>
                                    <div className='prof-div'>
                                        <div className='imgdiv'>
                                            <img className='perimg' src={process.env.PUBLIC_URL + '/assets/images/home/testimonials/person3.png'} />
                                        </div>
                                        <div className='namediv my-auto'>
                                            <p className='name'>vicky Sharma</p>
                                            <p className='position'>Data Analyst</p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className='slider-div'>
                                    <div className='top-div'>
                                        <div className='icodiv'>
                                            <img className='icon' src={process.env.PUBLIC_URL + '/assets/images/icons/ulta.png'} />
                                            <img className='icon' src={process.env.PUBLIC_URL + '/assets/images/icons/ulta.png'} />
                                        </div>
                                        <p className='text'>"From cybersecurity to managed IT services, Profcyma has been our trusted partner for over five years. Their innovative approach keeps our systems secure and up-to-date."</p>
                                    </div>
                                    <div className='prof-div'>
                                        <div className='imgdiv'>
                                            <img className='perimg' src={process.env.PUBLIC_URL + '/assets/images/home/testimonials/person3.png'} />
                                        </div>
                                        <div className='namediv my-auto'>
                                            <p className='name'>Mayank Patil</p>
                                            <p className='position'>COO, Secure Enterprises</p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className='slider-div'>
                                    <div className='top-div'>
                                        <div className='icodiv'>
                                            <img className='icon' src={process.env.PUBLIC_URL + '/assets/images/icons/ulta.png'} />
                                            <img className='icon' src={process.env.PUBLIC_URL + '/assets/images/icons/ulta.png'} />
                                        </div>
                                        <p className='text'>"Their enterprise solutions improved our team's productivity by 40%. Profcyma understood our needs and delivered beyond our expectations."</p>
                                    </div>
                                    <div className='prof-div'>
                                        <div className='imgdiv'>
                                            <img className='perimg' src={process.env.PUBLIC_URL + '/assets/images/home/testimonials/person3.png'} />
                                        </div>
                                        <div className='namediv my-auto'>
                                            <p className='name'>Rajiv Patel</p>
                                            <p className='position'>CTO, Global Solutions Inc.</p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className='slider-div'>
                                    <div className='top-div'>
                                        <div className='icodiv'>
                                            <img className='icon' src={process.env.PUBLIC_URL + '/assets/images/icons/ulta.png'} />
                                            <img className='icon' src={process.env.PUBLIC_URL + '/assets/images/icons/ulta.png'} />
                                        </div>
                                        <p className='text'>"Profcyma transformed our business processes with their innovative solutions.
                                            Their team was professional, responsive,And  exceeded our expectations Highly
                                            recommended!"</p>
                                    </div>
                                    <div className='prof-div'>
                                        <div className='imgdiv'>
                                            <img className='perimg' src={process.env.PUBLIC_URL + '/assets/images/home/testimonials/person2.jpg'} />
                                        </div>
                                        <div className='namediv my-auto'>
                                            <p className='name'>Aditya Jagtap</p>
                                            <p className='position'>Software Developer</p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>

                        </Swiper>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Testimonials