import React from 'react'

const TechnologyTitle = (props) => {
    return (
        <div className="ttilebg">
            <div className="row">
                <div className="">
                    <p className="devtit">{props?.title}</p>
                </div>
                {/* <div className="col-md-6 ">
                    <p className="content">
                        {props?.descn}
                    </p>
                </div> */}
            </div>
        </div>
    )
}

export default TechnologyTitle
