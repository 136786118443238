import React from 'react'
import FrontendTechnology from './FrontendTechnology/FrontendTechnology'
import Service_breadcrumb from './Service_breadcrumb/Service_breadcrumb'
import BackendTechnology from './BackendTechnology/BackendTechnology'
import CRMTechnology from './CRMTechnology/CRMTechnology'
import ServerCloud from './ServerCloud/ServerCloud'
import Database from './Database/Database'
import Common_Button from '../Common_Component/Common_Button/Common_Button'
import './Technologies.css'
import { useNavigate } from 'react-router-dom'

const Technologies = () => {

     const navigate = useNavigate("")

    return (
        <section className='Technologies'>
            <div className='container'>

                <Service_breadcrumb />

                <div className='text-center'>
                    <h1 className='whotitle'>Tech Solutions</h1>
                    <h5 className='secsub-title'>We work on various technologies to leverage the right benefit suited for your specific needs.</h5>
                </div>


                <div className='row justify-content-center'>
                    <div className='col-xl-4 col-lg-6 col-md-8 col-sm-10'>
                        <div className='contact_info_div'>
                            <p className='title'>Technologies</p>

                            <p className='subtext'>
                                We maintain technology neutrality, identifying and adopting technologies according to business needs
                            </p>

                            <div className='contact_div' onClick={() =>{navigate("/contact-us")}}>
                                <Common_Button btnText="Contact Us" />
                            </div>
                        </div>
                    </div>
                </div>

                <FrontendTechnology />

                <BackendTechnology />

                <CRMTechnology />

                <ServerCloud />

                <Database />

            </div>
        </section>
    )
}

export default Technologies
