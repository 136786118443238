import React, { useEffect, useState } from "react";
import "./Header.css";
import { Link, useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-regular-svg-icons";

import It_solution_offcanvas from "../Common_Component/offcanvas/It_Solution_offcanvas/It_solution_offcanvas";

import {
  faArrowRight,
  faChevronDown,
  faMagnifyingGlass,
  faShoppingCart,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { Col, NavDropdown, Row, Tab } from "react-bootstrap";
import {
  faFacebookF,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import Offcanvas from 'react-bootstrap/Offcanvas';
import Accordion from "react-bootstrap/Accordion";

function Header() {

  
  const location = useLocation();
  const [activeLink, setActiveLink] = useState("home");
  const [activeKey, setActiveKey] = useState(null);

  useEffect(() => {
    const path = location.pathname.substring(1);
    setActiveLink(path || "home");
  }, [location.pathname]);

  const handleNavItemClick = (selectedLink) => {
    setActiveLink(selectedLink);
    setActiveKey(null);
    const navbarToggle = document.querySelector(".navbar-toggler");
    if (navbarToggle && window.innerWidth <= 992) {
      navbarToggle.click();
    }
  };



  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);



  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);


  const itsolution = [
    {
      name: 'Website Development',
      image: '/assets/images/header/it-solutions/website.png',
      url: '/portfolio'
    },
    {
      name: 'App Development',
      image: '/assets/images/header/it-solutions/app.png',
      url: '/portfolio'
    },
    {
      name: 'Digital Marketing',
      image: '/assets/images/header/it-solutions/digital.png',
      url: '/portfolio'
    },
    {
      name: 'UI/UX Design',
      image: '/assets/images/header/it-solutions/ui-ux.png',
      url: '/portfolio'
    },
    {
      name: 'Software Development',
      image: '/assets/images/header/it-solutions/website.png',
      url: '/portfolio'
    },
    {
      name: 'Brand Awarenesss',
      image: '/assets/images/header/it-solutions/brand.png',
      url: '/portfolio'
    },
    {
      name: 'Graphics Designing',
      image: '/assets/images/header/it-solutions/graphics.png',
      url: '/portfolio'
    },
    {
      name: 'E-commerce Development',
      image: '/assets/images/header/it-solutions/ecom.png',
      url: '/portfolio'
    },

  ];


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const [showOffcanvas, setShowOffcanvas] = React.useState(false);

  const handleOffcanvasToggle = () => setShowOffcanvas((prev) => !prev);



  return (
    <>
      <section className="main-head-sec">
        <Container>
          <Navbar expand="lg">


            <Navbar.Brand as={Link} to="/">
              <img
                className="headlogo"
                src={
                  process.env.PUBLIC_URL + "/assets/images/logo/logo.png"
                }
                alt="Logo"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav ">
              <div></div>
              <Nav className="">
                <Nav.Link
                  as={Link}
                  to="/"
                  className={location.pathname === "/" ? "active" : ""}
                  onClick={handleNavItemClick}
                >
                  Home
                </Nav.Link>

                <Nav.Link
                  as={Link}
                  to="/who-we-are"
                  className={location.pathname === "/who-we-are" ? "active" : ""}
                  onClick={handleNavItemClick}
                >
                  Who we are
                </Nav.Link>



                <Nav.Link
                  as={Link}
                  to="/portfolio"
                  className={location.pathname === "/portfolio" ? "active" : ""}
                  onClick={handleNavItemClick}
                >
                  Portfolio
                </Nav.Link>

                <Nav.Link
                  // onClick={handleShow2}
                  onClick={handleOffcanvasToggle}
                >
                  IT Solutions
                </Nav.Link>

                <Nav.Link
                  as={Link}
                  to="/gallery"
                  className={location.pathname === "/gallery" ? "active" : ""}
                  onClick={handleNavItemClick}
                >
                  Gallery
                </Nav.Link>

                <Nav.Link
                  as={Link}
                  to="/technologies"
                  className={location.pathname === "/technologies" ? "active" : ""}
                  onClick={handleNavItemClick}
                >
                  Technologies
                </Nav.Link>

                <Nav.Link
                  as={Link}
                  to="/blogs"
                  className={location.pathname === "/blogs" ? "active" : ""}
                  onClick={handleNavItemClick}
                >
                  Blogs
                </Nav.Link>

                <Nav.Link
                  as={Link}
                  to="/career"
                  className={location.pathname === "/career" ? "active" : ""}
                  onClick={handleNavItemClick}
                >
                  Career
                </Nav.Link>

                <Nav.Link
                  as={Link}
                  to="/contact-us"
                  className={location.pathname === "/contact-us" ? "bgcont" : "bgcont"}
                  onClick={handleNavItemClick}
                >
                  Contact us
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Container>



        {/* {['top'].map((placement, idx) => (
          <Offcanvas className="itsoldropoffcanvas" key={idx} placement={placement} name={placement} show={show2} onHide={handleClose2} >
            <Offcanvas.Header closeButton onClick={handleNavItemClick}>
              <Offcanvas.Title></Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="">
              <div className="container">
                <div className="row">
                  {itsolution?.map((data, index) => (
                    <div className="col-lg-4 col-6 mb-3">
                      <Link to={data.url} onClick={handleNavItemClick}>
                        <div className="solcard" onClick={handleClose2}>
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="imgdiv">
                                <img className="solimg" src={process.env.PUBLIC_URL + data?.image} />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="namediv">
                                <h6 className="name">{data?.name}</h6>
                                <p className="exsol">Explore Solution <FontAwesomeIcon icon={faArrowRight} /></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        ))} */}

        <It_solution_offcanvas
            showOffcanvas={showOffcanvas} 
            handleClose={() => setShowOffcanvas(false)} 
        />


      </section>
    </>
  );
}

export default Header;