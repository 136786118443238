import React from 'react'
import './FrontendTechnology.css'
import Service_development_Card from '../../Common_Component/Service_development_Card/Service_development_Card'
import Technology_Card from '../Technology_Card/Technology_Card'
import TechnologyTitle from '../TechnologyTitle/TechnologyTitle'

const FrontendTechnology = () => {
    return (
        <section className="FrontendTechnology Service_development_sec">
            <div className="container ">
                <div className="bg-servicedev">

                  <TechnologyTitle title="Front - End & Mobile Technologies" />

                    <div className="Service_development_Card_Holder">
                        <div className="row">
                            <div className="col-xl-10 col-md-11 col-11 mx-auto">
                                <div className="row">
                                    <div className="col-lg-3 col-md-4  col-sm-4 col-6  after_border_holder">
                                        <Technology_Card imgSrc={"/assets/images/technologies/html-5.webp"} headingText={"HTML"} />
                                    </div>

                                    <div className="col-lg-3 col-md-4  col-sm-4 col-6  after_border_holder">
                                        <Technology_Card  imgSrc={"/assets/images/technologies/js-1.webp"} headingText={"javacript"} />
                                    </div>

                                    <div className="col-lg-3 col-md-4  col-sm-4 col-6  after_border_holder">
                                        <Technology_Card  imgSrc={"/assets/images/technologies/react.webp"} headingText={"React Native"} />
                                    </div>

                                    <div className="col-lg-3 col-md-4  col-sm-4 col-6  ">
                                        <Technology_Card  imgSrc={"/assets/images/technologies/ios.webp"} headingText={"iOS"} />
                                    </div>

                                    <div className="col-lg-3 col-md-4  col-sm-4 col-6  after_border_holder">
                                        <Technology_Card  imgSrc={"/assets/images/technologies/react.webp"} headingText={"React JS"} />
                                    </div>

                                    <div className="col-lg-3 col-md-4  col-sm-4 col-6  after_border_holder">
                                        <Technology_Card  imgSrc={"/assets/images/technologies/android.webp"} headingText={"Android"} />
                                    </div>

                                    <div className="col-lg-3 col-md-4  col-sm-4 col-6  after_border_holder">
                                        <Technology_Card  imgSrc={"/assets/images/technologies/angular.webp"} headingText={"Angular"} />
                                    </div>

                                    <div className="col-lg-3 col-md-4  col-sm-4 col-6  ">
                                        <Technology_Card  imgSrc={"/assets/images/technologies/flutter.webp"} headingText={"Flutter"} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FrontendTechnology
