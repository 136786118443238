import React from 'react'
import Service_development_Card from '../../Common_Component/Service_development_Card/Service_development_Card'
import Technology_Card from '../Technology_Card/Technology_Card'
import TechnologyTitle from '../TechnologyTitle/TechnologyTitle'

const Database = () => {
    return (
        <section className="Database FrontendTechnology Service_development_sec">
            <div className="container ">
                <div className="bg-servicedev">

                  <TechnologyTitle title="Database" />

                    <div className="Service_development_Card_Holder">
                        <div className="row">
                            <div className="col-xl-10 col-md-11 col-11 mx-auto ">
                                <div className="row">
                                    <div className="col-lg-3 col-md-4  col-sm-4 col-6 after_border_holder ">
                                        <Technology_Card imgSrc={"/assets/images/technologies/mysql.webp"} headingText={"Azure"} />
                                    </div>

                                    <div className="col-lg-3 col-md-4  col-sm-4 col-6 after_border_holder">
                                        <Technology_Card  imgSrc={"/assets/images/technologies/sqlserver-logo.webp"} headingText={"Ms Sql"} />
                                    </div>

                                    <div className="col-lg-3 col-md-4  col-sm-4 col-6 after_border_holder">
                                        <Technology_Card  imgSrc={"/assets/images/technologies/oracle-logo.webp"} headingText={"Oracle"} />
                                    </div>


                                    <div className="col-lg-3 col-md-4  col-sm-4 col-6 ">
                                        <Technology_Card  imgSrc={"/assets/images/technologies/mongodb.webp"} headingText={"Mongo Db"} />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Database
